<template>
 <div id="page-container" class="pb-5">
  <div class="content-wrap">
   <PbxDashboard
    :buttonSelected="this.buttonSelected"
    :extensions="extensions"
    :dataColumnChartQuarterHourlyCons="dataColumnChartCons"
    :dataColumnChartQuarterHourly="dataColumnChart"
    :dataColumnChartHourly="dataColumnChart"
    :dataColumnChartHourlyCons="dataColumnChartCons"
    :periodSelector="this.periodSelector"
    :timeSelected="this.timeSelected"
    :textMonth="this.textMonth"
    :urlType="urlType" />
  </div>
  <!-- <div v-show="extensions.length === 0"><EmptyStateDashboard /></div> -->
 </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import PbxDashboard from "../../components/PBX/PbxDashboard.vue";
import EmptyStateDashboard from "../../components/PBX/EmptyStateDashboard.vue";

export default {
 props: ["presentationKey"],
 components: {
  EmptyStateDashboard,
  PbxDashboard,
 },
 data() {
  return {
   fullPage: true,
   periodSelector: undefined,
   dataColumnChartQuarterHourly: [],
   dataColumnChartQuarterHourlyCons: [],
   dataColumnChartHourly: [],
   dataColumnChartHourlyCons: [],
   dataColumnChart: [],
   dataColumnChartCons: [],
   extensions: [],
   timeSelected: [],
   isAllSelected: "",
   buttonSelected: "month",
   textMonth: "",
   data: undefined,
  };
 },
 methods: {
  async getPbxData(key) {
   try {
    const res = await axios.get(
     `https://xp-stats-101.bluerock.tel/api/v1.2/reports/${key}`
    );
    console.log(res.data);
    return res.data;
   } catch (error) {
    this.$emit("setLoading", false);
    this.$emit("error404", error.response);
    this.errorHandling(error);
   }
  },
  formatPeriod(start, end) {
   return start.split(" ")[0] + "-" + end.split(" ")[0];
  },
  async getDataFromElementForColumn(key) {
   this.$emit("setLoading", true);

   let res = undefined;

   res = await this.getPbxData(key);

   let parameters = res.parameters;

   this.urlType = parameters.element_type == 4 ? "queue" : "extension";
   this.periodSelector = this.formatPeriod(parameters.start, parameters.end);

   this.dataColumnChart = [];
   this.dataColumnChartCons = [];
   let period = parameters.period;
   let type = parameters.type;
   let arrayOfElements = [];
   let stringOfElements = parameters.dns;
   this.extensions.length = 0;

   this.$store.dispatch("hostName", parameters.host_name);

   this.$store.dispatch("dataSet", parameters.report_type);

   //  this.$store.dispatch("hostTimeZone", parameters.timezone ? parameters.timezone : 0);

   let timeSelected = [];

   if (parameters.time) {
    let splitTime = parameters.time.split(":");
    timeSelected[0] = splitTime[0];
    timeSelected[1] = splitTime[1];
   }

   this.timeSelected = timeSelected;

   arrayOfElements = stringOfElements.split(",");

   let obj = {};
   let objCons = {};
   let dataObjAns = {};
   let dataObjAnsCons = {};
   let dataObjUn = {};
   let dataObjUnCons = {};

   if (this.dataSet === "answeredDaily") {
    let resData = res.data;

    this.dataColumnChart = [];
    this.dataColumnChartCons = [];
    let keys = [];
    let keysConc = [];

    if (timeSelected.length > 0) {
     if (
      timeSelected[0].slice(-2) === "00" &&
      timeSelected[1].slice(-2) === "00"
     ) {
      timeSelectedIsHour = true;
     } else {
      timeSelectedIsQuarter = true;
     }
     startTimeSelected = timeSelected[0];
     endTimeSelected = timeSelected[1];
    }

    keys = Object.keys(resData.daily);
    keysConc = Object.keys(resData["daily*"]);

    let days = [];
    days = resData.days;

    let formatedDays = [];

    days.forEach(day => {
     formatedDays.push(this.formatDateApiRequest(day));
    });

    console.log(
     "getDataFromElementForColumn ~ arrayOfElements:",
     arrayOfElements
    );
    if (keys.length > 0) {
     for (let arrIndex = 0; arrIndex < arrayOfElements.length; arrIndex++) {
      obj = {};
      objCons = {};
      dataObjUn = {};
      dataObjUnCons = {};
      dataObjAns = {};
      dataObjAnsCons = {};
      dataObjAns["name"] = "Répondus";
      dataObjAns["data"] = [];
      dataObjAnsCons["name"] = "Répondus";
      dataObjAnsCons["data"] = [];
      dataObjUn["name"] = "Non répondus";
      dataObjUn["data"] = [];
      dataObjUnCons["name"] = "Non répondus";
      dataObjUnCons["data"] = [];
      obj["data"] = [];
      objCons["data"] = [];
      obj["labels"] = [];
      objCons["labels"] = [];

      let filledArrayDataAns = new Array(days.length).fill(0);
      let filledArrayDataUns = new Array(days.length).fill(0);
      let filledArrayDataAnsCons = new Array(days.length).fill(0);
      let filledArrayDataUnsCons = new Array(days.length).fill(0);
      let filledArrayDataAnsHourly = [];
      let filledArrayDataAnsHourlyCons = [];
      let filledArrayDataUnsHourly = [];
      let filledArrayDataUnsHourlyCons = [];

      keys.forEach((element, index) => {
       let splitElement = element.split(":");
       let elementName = "";
       let requestDay = splitElement[0];
       elementName =
        this.urlType === "queue"
         ? resData.map[arrayOfElements[arrIndex]]?.name
         : resData.map[arrayOfElements[arrIndex]];
       if (splitElement[2] == arrayOfElements[arrIndex]) {
        obj["name"] = splitElement[2] + " - " + elementName;
        if (splitElement[3] === "answered") {
         for (let index = 0; index < formatedDays.length; index++) {
          const elementDay = formatedDays[index];
          if (elementDay === requestDay) {
           filledArrayDataAns.splice(
            index,
            1,
            parseInt(resData.daily[element]) <=
             parseInt(resData["daily"][element.replace("answered", "calls")])
             ? parseInt(resData.daily[element])
             : parseInt(resData["daily"][element.replace("answered", "calls")])
           );
          }
         }
         dataObjAns["name"] = "Répondus";
         dataObjAns["data"] = filledArrayDataAns;
        }

        if (splitElement[3] === "calls") {
         for (let index = 0; index < formatedDays.length; index++) {
          const elementDay = formatedDays[index];
          if (elementDay === requestDay) {
           filledArrayDataUns.splice(
            index,
            1,
            parseInt(resData["daily"][element]) -
             (parseInt(resData["daily"][element.replace("calls", "answered")])
              ? parseInt(
                 resData["daily"][element.replace("calls", "answered")]
                ) <= parseInt(resData["daily"][element])
                ? parseInt(
                   resData["daily"][element.replace("calls", "answered")]
                  )
                : parseInt(resData["daily"][element])
              : 0)
           );
          }
         }
         dataObjUn["name"] = "Non répondus";
         dataObjUn["data"] = filledArrayDataUns;
        }
       } else {
        // in case there is key but no data for element
        obj["name"] = arrayOfElements[arrIndex] + " - " + elementName;
       }
      });

      keysConc.forEach((element, index) => {
       let splitElement = element.split(":");
       let elementName = "";
       let requestDay = splitElement[0];
       elementName =
        this.urlType === "queue"
         ? resData.map[arrayOfElements[arrIndex]]?.name
         : resData.map[arrayOfElements[arrIndex]];
       if (splitElement[2] === "*") {
        if (splitElement[3] === "answered") {
         for (let index = 0; index < formatedDays.length; index++) {
          const elementDay = formatedDays[index];
          if (elementDay === requestDay) {
           filledArrayDataAnsCons.splice(
            index,
            1,
            parseInt(resData["daily*"][element]) <=
             parseInt(resData["daily*"][element.replace("answered", "calls")])
             ? parseInt(resData["daily*"][element])
             : parseInt(resData["daily*"][element.replace("answered", "calls")])
           );
          }
         }
         dataObjAnsCons["name"] = "Répondus";
         dataObjAnsCons["data"] = filledArrayDataAnsCons;
        }

        if (splitElement[3] === "calls") {
         for (let index = 0; index < formatedDays.length; index++) {
          const elementDay = formatedDays[index];
          if (elementDay === requestDay) {
           filledArrayDataUnsCons.splice(
            index,
            1,
            parseInt(resData["daily*"][element]) -
             (parseInt(resData["daily*"][element.replace("calls", "answered")])
              ? parseInt(
                 resData["daily*"][element.replace("calls", "answered")]
                ) <= parseInt(resData["daily*"][element])
                ? parseInt(
                   resData["daily*"][element.replace("calls", "answered")]
                  )
                : parseInt(resData["daily*"][element])
              : 0)
           );
          }
         }
         dataObjUnCons["name"] = "Non répondus";
         dataObjUnCons["data"] = filledArrayDataUnsCons;
        }
       } else {
        // in case there is key but no data for element
        obj["name"] = arrayOfElements[arrIndex] + " - " + elementName;
       }
      });

      obj["labels"] = days;
      objCons["labels"] = days;

      obj["data"].splice(0, 1, dataObjAns);
      obj["data"].splice(1, 1, dataObjUn);

      objCons["data"].splice(0, 1, dataObjAnsCons);
      objCons["data"].splice(1, 1, dataObjUnCons);

      this.extensions.splice(arrIndex, 1, obj["name"]);
      this.dataColumnChart.splice(arrIndex, 1, obj);
      this.dataColumnChartCons.splice(0, 1, objCons);
     }
    } else {
     for (let arrIndex = 0; arrIndex < arrayOfElements.length; arrIndex++) {
      obj = {};
      objHourly = {};
      objCons = {};
      let elementName = "";
      elementName =
       this.urlType === "queue"
        ? resData.map[arrayOfElements[arrIndex]]?.name
        : resData.map[arrayOfElements[arrIndex]];

      obj["name"] = arrayOfElements[arrIndex] + " - " + elementName;
      objCons["name"] = "cons";
      obj["labels"] = [];
      objCons["labels"] = [];
      obj["labels"] = [];
      obj["data"] = [];

      this.extensions.splice(arrIndex, 1, obj["name"]);
      this.dataColumnChart.splice(arrIndex, 1, obj);
      this.dataColumnChartCons.splice(0, 1, objCons);
     }
    }
   }
   console.log(
    "getDataFromElementForColumn ~ this.dataColumnChart:",
    this.dataColumnChart
   );
   this.$emit("setLoading", false);
  },
  async getTimeLabelRange(arr) {
   let sortedArray = arr.sort((a, b) => {
    return a - b;
   });
   let start = ("0" + String(sortedArray[0])).slic;
   let end = ("0" + String(sortedArray[arr.length - 1])).slic;
   try {
    const res = await axios.post(
     `https://telecom0585-clients.bluerocktel.net/api/v1/helpers/time/timeLabels?start=${start}&end=${end}`
    );
    return res.data;
   } catch (error) {
    console.log(error);
    this.errorHandling(error);
   }
  },
  async getTimeLabelRangeStartAndEnd(start, end) {
   try {
    const res = await axios.post(
     `https://telecom0585-clients.bluerocktel.net/api/v1/helpers/time/timeLabels?start=${start}&end=${end}`
    );
    return res.data;
   } catch (error) {
    console.log(error);
    this.errorHandling(error);
   }
  },
  async getTimeLabelRangeHourly(arr) {
   let hourlyRange = [];
   let sortedArray = arr.sort((a, b) => {
    return a - b;
   });
   let end = "";
   let start = ("0" + String(sortedArray[0]) + "00").slice(0, 4);
   if (String(sortedArray[arr.length - 1]).length > 1) {
    end = (String(sortedArray[arr.length - 1]) + "00").slice(0, 4);
   } else {
    end = ("0" + String(sortedArray[arr.length - 1]) + "00").slice(0, 4);
   }
   try {
    const res = await axios.post(
     `https://telecom0585-clients.bluerocktel.net/api/v1/helpers/time/timeLabels?start=${start}&end=${end}`
    );
    for (let index = 0; index < res.data.length; index++) {
     const element = res.data[index];
     if (element.slice(-2) == "00") {
      hourlyRange.push(element.slice(0, 2));
     }
    }
    return hourlyRange;
   } catch (error) {
    console.log(error);
    this.errorHandling(error);
   }
  },
  async getTimeLabelRangeHourlyStartAndEnd(start, end) {
   let hourlyRange = [];
   try {
    const res = await axios.post(
     `https://telecom0585-clients.bluerocktel.net/api/v1/helpers/time/timeLabels?start=${start}&end=${end}`
    );
    for (let index = 0; index < res.data.length; index++) {
     const element = res.data[index];
     if (element.slice(-2) == "00") {
      hourlyRange.push(element.slice(0, 2));
     }
    }
    return hourlyRange;
   } catch (error) {
    console.log(error);
    this.errorHandling(error);
   }
  },
 },
 mounted() {
  this.getDataFromElementForColumn(this.presentationKey);
 },
 computed: {
  ...mapGetters([
   "account",
   "pbxElements",
   "pbxTimeSelected",
   "hostName",
   "hostContext",
   "hostTimeZone",
   "dataSet",
  ]),
 },
 watch: {},
};
</script>

<style>
#page-container {
 position: relative;
 min-height: 97%;
}
#content-wrap {
 padding-bottom: 2rem; /* Footer height */
}
#footer {
 position: absolute;
 bottom: 0;
 width: 100%;
 height: 2.5rem; /* Footer height */
}
</style>
